import { nanoid } from 'nanoid';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import FormSelect from '../../../../components/FormSelect';
import { typecircuit } from '../../enums';
import FormInputDate from '../../../../components/FormInputDate';
import FormInputCheckBox from '../../../../components/FormInputCheckBox';
import { getGrue, getSiloGrue } from '../../api';
import { usePrefTechStore } from '../../../../stores/prefTech';
import { parse } from 'date-fns';
import { fr } from 'date-fns/locale';

const EditForm = ({ onSubmit, circuitChampProps }) => {
  const defaultValues = {
    grue: circuitChampProps.code_grue != null ? circuitChampProps.code_grue : '',
    silo: circuitChampProps.code_silo != null ? circuitChampProps.code_silo : '',
    typecircuit: circuitChampProps.type_circuit != null ? circuitChampProps.type_circuit : '',
    enddisplay: circuitChampProps.end_display ? parse(circuitChampProps.end_display, 'yyyy-MM-dd', new Date(), { locale: fr }) : null,
    supprimable: circuitChampProps.supprimable_grue === 'O' ? true : false,
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    control,
  } = methods;

  const watchGrue = watch('grue');
  const watchTypeCircuit = watch('typecircuit');

  const [typecircuits] = useState(typecircuit);
  const [grues, setGrues] = useState([]);
  const [siloGrue, setSiloGrue] = useState([]);
  const [disabledGrue, setDisabledGrue] = useState(false);
  const [disabledSilo, setDisabledSilo] = useState(true);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  //init grues list
  useEffect(() => {
    const usl = usePrefTechStore.getState().prefs.USL;
    (async () => {
      const data = await getGrue(usl);
      const mapGrue = data.map((item) => ({
        id: nanoid(),
        value: item.numerogrue,
        label: item.numerogrue,
      }));
      setGrues(mapGrue);
      setDisabledSilo(true);
    })();
  }, []);

  // init silo list after select grue
  useEffect(() => {
    if (watchGrue != '') {
      (async () => {
        const usl = usePrefTechStore.getState().prefs.USL;
        const dataSilo = await getSiloGrue(usl, watchGrue);
        const mapSilo = dataSilo.map((item) => ({
          id: nanoid(),
          value: item.id,
          label: item.nom,
        }));
        setValue('typecircuit', 'B');
        setSiloGrue(mapSilo);
        setDisabledSilo(false);
      })();
    }
  }, [setValue, watchGrue]);

  // Disabled select of grue & silo if 'typecircuit' is not equal to B
  useEffect(() => {
    if (watchTypeCircuit == 'B') {
      setDisabledGrue(false);
      setDisabledSilo(true);
    } else if (watchTypeCircuit != 'B') {
      setValue('grue', '');
      setValue('silo', '');
      setDisabledGrue(watchTypeCircuit && watchTypeCircuit != 'B');
      setDisabledSilo(watchTypeCircuit && watchTypeCircuit != 'B');
    }
  }, [setValue, watchTypeCircuit]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Modifier un circuit champ</Typography>
        <Divider />
        <FormSelect
          name="typecircuit"
          control={control}
          label="Type de circuit (facultatif)"
          MenuProps={MenuProps}
        >
          {typecircuits.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="grue"
          control={control}
          label="Grue (facultatif)"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabledGrue }}
        >
          {grues.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="silo"
          control={control}
          label="Silo (facultatif)"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabledSilo }}
        >
          {siloGrue.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputDate
          name="enddisplay"
          control={control}
          label="Date de fin d'affichage (facultatif)"
          inputProps={{ readOnly: true }}
        />
        <FormInputCheckBox name="supprimable" control={control} label="Permettre au TG de supprimer ce circuit" />
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'MODIFIER LE CIRCUIT'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default EditForm;
