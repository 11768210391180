import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { format } from 'date-fns';

const createGeometry = ({ feature, data }) => {
  const insertFeature = new Feature();

  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('id', data.pictoId ? data.pictoId : null);
  insertFeature.set('label', data.label ? data.label : '');
  insertFeature.set('code_grue', data.grue ? data.grue : null);
  insertFeature.set('type_picto', data.typepicto ? data.typepicto : null);
  insertFeature.set('commentaire', data.comment ? data.comment : '');
  insertFeature.set(
    'datefin',
    data.enddisplaydate ? format(data.enddisplaydate, 'yyyy-MM-dd') : null
  );
  insertFeature.set('supprimable_grue', data.supprimable ? 'O' : 'N');

  // Permet de passer les données à la feature créer et lui appliquer le bon style (avant l'appel d'init).
  feature.setProperties(insertFeature.getProperties());

  // create feature collection GeoJSON
  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    insertFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometry = async ({ feature }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometryProperties = async ({ feature, data }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());
  updateFeature.set('id', data.pictoId ? data.pictoId : null);
  updateFeature.set('label', data.label ? data.label : '');
  updateFeature.set('code_grue', data.grue ? data.grue : null);
  updateFeature.set('type_picto', data.typepicto ? data.typepicto : null);
  updateFeature.set('commentaire', data.comment ? data.comment : '');
  updateFeature.set(
    'datefin',
    data.enddisplaydate ? format(data.enddisplaydate, 'yyyy-MM-dd') : null
  );
  updateFeature.set('supprimable_grue', data.supprimable ? 'O' : 'N');

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

export { createGeometry, updateGeometry, updateGeometryProperties };
