import { axios, geoApiGouv, wxsIgn } from '../../../lib/axios';

const getCodeInseeFromXY = async ([x, y]) => {
  const [{ code, nom }] = await geoApiGouv.get(`/communes?lon=${x}&lat=${y}&fields=code`);
  return {codeInsee: code.substring(0, 5), nomCommune: nom};
};

const getSilos = async ({ codeplanteur, codesilo, commune, passage, secteur }) => {
  const { planning } = await axios.get(
    `/planning-betteraves/silo?codeplanteur=${codeplanteur || ''}&codesilo=${
      codesilo || ''
    }&commune=${commune || ''}&passage=${passage || ''}&secteur=${secteur || ''}`
  );
  return planning;
};

// TODO typeNivelage and typeBachage
const updateSiloProps = async ({
  id,
  usl,
  comemps,
  codeemps,
  datarrache,
  commweb,
  vupartech,
  surfarrachee,
}) => {
  const { planning } = await axios.put(
    `/planning-betteraves/silo?siloId=${id || ''}&usl=${usl || ''}&codeInsee=${
      comemps || ''
    }&emplacement=${codeemps || ''}&dateArrachage=${datarrache || ''}&surfaceArrachee=${
      surfarrachee || ''
    }&typeNivelage=${''}&typeBachage=${''}&commWeb=${commweb || ''}&vuParTechnicien=${
      vupartech || ''
    }&saisieParTechnicien=O`
  );
  return planning;
};

const updateSiloCoords = async ({ id, usl, codeModif, posX, posY, etiqPosX, etiqPosY }) => {
  const { planning } = await axios.put(
    `/planning-betteraves/silo/position?siloId=${id || ''}&siloUsl=${usl || ''}&posX=${
      posX || ''
    }&posY=${posY || ''}&etiqPosX=${etiqPosX || ''}&etiqPosY=${etiqPosY || ''}&codeModif=${
      codeModif || ''
    }&saisieParTechnicien=O`
  );
  return planning;
};

const getSilosGrue = async ({ codegrue, usine }) => {
  const { planning } = await axios.get(
    `/planning-betteraves/silo/grue?grue=${codegrue || ''}&usl=${usine || ''}`
  );
  return planning;
};

const getSiloPositionEncodage = async ({ usine, siloId }) => {
  const { livraisonsReceptions } = await axios.get(
    `/livraisons-receptions/silo/encodagecamion?silo=${siloId || ''}&usl=${usine || ''}`
  );
  return livraisonsReceptions;
};

const getSiloEmplacements = async ({ codeInsee }) => {
  const { divers } = await axios.get(`/divers/airestockage/commune?codeinsee=${codeInsee || ''}`);
  return divers;
};

const getCodeInseeInExtent = async ({ extent }) => {
  const { features } = await wxsIgn.get(
    `/administratif/geoportail/wfs?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&typeName=ADMINEXPRESS-COG-CARTO.LATEST:commune&outputFormat=application/json&count=8&srsName=EPSG:4326&propertyName=insee_com&BBOX=${
      extent.join(',') || ''
    }&propertyName=insee_com`
  );
  return features;
};

const getCommunes = async ({ text }) => {
  const { divers } = await axios.get(`/divers/commune?choix=1&nom=${text || ''}`);
  return divers;
};

const getCommunesTech = async ({ nom, type }) => {
  const { divers } = await axios.get(`/divers/commune?nom=${nom || ''}&type=${type || ''}`);
  return divers;
};

const getCommunesUsine = async ({ usine }) => {
  const { divers } = await axios.get(`/divers/commune/${usine}`);
  return divers;
};

const getSecteur = async ({ text }) => {
  const { divers } = await axios.get(`/divers/secteurgeo?chaine=${text.toUpperCase() || ''}`);
  return divers?.dssecteur.map(secteur => {
    secteur.secteurLibelle = secteur.secteurGrue + ' - ' + secteur.nomentreprise;
    return secteur;
  });
};

const getUsines = async () => {
  const { divers } = await axios.get('/divers/usine/cu');
  return divers;
};

const getTechPrefs = async () => {
  const { divers } = await axios.get('/divers/technicien/prefs');
  return divers;
};

const getTechList = async () => {
  const { divers } = await axios.get('/divers/technicien/recherche?carto=O&windows=O');
  return divers;
};

const getTechType = async () => {
  const { divers } = await axios.get('/divers/technicien/types');
  return divers;
};

const getSilosApi = async ({ bbox, codeplanteur, codesilo, commune, passage, secteur }) => {
  const response = await axios.get(
    `/parcelles-api/silos/search-silo?bbox=${bbox || ''}&codeplanteur=${
      codeplanteur || ''
    }&codesilo=${codesilo || ''}&commune=${commune || ''}&passage=${passage || ''}&secteur=${
      secteur || ''
    }`
  );
  return response;
};

const getSilosGrueApi = async ({ codegrue, usine }) => {
  const response = await axios.get(
    `/parcelles-api/silos/search-silo-grue?grue=${codegrue || ''}&usl=${usine || ''}`
  );
  return response;
};

const getSiloPositionEncodageApi = async ({ usine, siloId }) => {
  const response = await axios.get(
    `/parcelles-api/silos/get-encodage-camion?usine=${usine || ''}&silo=${siloId || ''}`
  );
  return response;
};

const updateSiloCoordsApi = async (silo) => {
  const response = await axios.put(`/parcelles-api/silos/update-coords-silo`, silo);
  return response;
};

const updateSiloPropsApi = async (silo) => {
  const response = await axios.put(`/parcelles-api/silos/update-info-silo`, silo);
  return response;
};

const getParametrage = async (type, media) => {
  const { divers } = await axios.get(`/divers/parametrage?type=${type || ''}&media=${media || ''}`);
  return divers;
};

const getEntreprise = async (typeEnt) => {
  const { synccartosia } = await axios.get(`sync-carto-sia/entreprises/${typeEnt}`);
  return synccartosia;
};

const getSiloByEntreprise = async (nom, type) => {
  const { planning } = await axios.get(
    `planning-betteraves/silo/entreprise?nom=${nom}&type=${type}`
  );
  return planning;
};

export {
  getSilos,
  updateSiloProps,
  updateSiloCoords,
  getSiloPositionEncodage,
  getSilosGrue,
  getSiloEmplacements,
  getCodeInseeFromXY,
  getCodeInseeInExtent,
  getCommunes,
  getSecteur,
  getUsines,
  getTechPrefs,
  getTechList,
  getTechType,
  getCommunesTech,
  getCommunesUsine,
  getSilosApi,
  getSilosGrueApi,
  getSiloPositionEncodageApi,
  updateSiloCoordsApi,
  updateSiloPropsApi,
  getParametrage,
  getEntreprise,
  getSiloByEntreprise,
};
